<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="d-flex flex-column">
          <b-row>
            <b-col cols="8">
              <h3>
                Data Perawatan Non Rutin
              </h3>
            </b-col>
            <b-col cols="4" style="text-align:end;" v-if="model.guarantor_type == 'impactor'">
              <b-button
              class="btn-sm" 
                variant="primary"
                type="button"
                @click.prevent="printKesanggupan"
              >
                Surat <br>Kesanggupan
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="model.guarantor_type == 'impactor' && model.issue_guarantor">
              <hr>
              <h4>Data Pengguna</h4>
              <label>Nama :</label>
              <p>{{ model.issue_guarantor.name }}</p>
              <label>Alamat :</label>
              <p>{{ model.issue_guarantor.address }}</p>
              <label>No. KTP / SIM :</label>
              <p>{{ model.issue_guarantor.no_ktp_sim }}</p>
              <label>No. Telp / HP :</label>
              <p>{{ model.issue_guarantor.no_telp_hp }}</p>
              <label>NOPOL :</label>
              <p>{{ model.issue_guarantor.number_plate }}</p>
              <label>Merk / Type :</label>
              <p>{{ model.issue_guarantor.merk }}</p>
              <label>Tahun :</label>
              <p>{{ model.issue_guarantor.tahun }}</p>
              <label>Warna :</label>
              <p>{{ model.issue_guarantor.warna }}</p>
            </b-col>
            <b-col cols="12" v-if="model.guarantor_type == 'assurance' && model.issue_guarantor">
              <hr>
              <h4>Data Asuransi</h4>
              <label>Nama :</label>
              <p>{{ model.issue_guarantor.name }}</p>
              <label>Alamat :</label>
              <p>{{ model.issue_guarantor.address }}</p>
              <label>No. Telp / HP :</label>
              <p>{{ model.issue_guarantor.no_telp_hp }}</p>
              <label>Email :</label>
              <p>{{ model.issue_guarantor.email }}</p>
            </b-col>
            <b-col cols="12">
              <hr>
              <h4>Data Kerusakan Kendaraan</h4>
              <label>Nama Perusahaan:</label>
              <p>{{ model.company_name }}</p>
              <label>Alamat Perusahaan:</label>
              <p>{{ model.company_address }}</p>
              <label>Pengemudi:</label>
              <p>{{ model.name }}</p>
              <label>phone:</label>
              <p>{{ model.phone }}</p>
              <label>Alamat Pengemudi:</label>
              <p>{{ model.address }}</p>
              <label>Nomor Polisi:</label>
              <p v-if="model.vehicle">{{ model.vehicle.number }}</p>
              <label>Judul Isu:</label>
              <p>{{ model.subject }}</p>
              <label>Tanggal Kejadian:</label>
              <p>{{ model.date }}</p>
              <label>Alamat Kejadian:</label>
              <p>{{ model.issue_address }}</p>
              <label>Kronologi:</label>
              <p>{{ model.description }}</p>
              <label>KM ODO Pelaporan:</label>
              <p>{{ model.odometer }} KM</p>
              <label v-if="model.author != null">Petugas Pelaporan:</label>
              <p v-if="model.author != null">
                {{ model.author.name }}
              </p>
              <label>Tanggal Masuk:</label>
              <p>{{ tanggal(model.created_at) }}</p>
              <label>Status</label>
              <br>
              <span
                v-if="model.status == 'open'"
                class="badge rounded-pill bg-info"
              >Baru</span>
              <span
                v-else-if="model.status == 'solved'"
                class="badge rounded-pill bg-success"
              >Selesai</span>
              <span
                v-else
                class="badge rounded-pill bg-warning"
              >{{model.status}}</span>
              <br>
              <br>
              <label>Foto Dashboard:</label>
              <br>
              <a
                :href="model.odometer_evidence"
                target="_blank"
              ><img
                :src="model.odometer_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label>Foto Isu Bersangkutan:</label>
              <br>
              <a
                :href="model.issue_evidence"
                target="_blank"
              ><img
                :src="model.issue_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label v-if="model.ktp_evidence">Foto KTP:</label>
              <br>
              <a v-if="model.ktp_evidence"
                :href="model.ktp_evidence"
                target="_blank"
              ><img
                :src="model.ktp_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label v-if="model.stnk_evidence">Foto STNK:</label>
              <br>
              <a v-if="model.stnk_evidence"
                :href="model.stnk_evidence"
                target="_blank"
              ><img
                :src="model.stnk_evidence"
                width="200"
                alt=""
              ></a>
              
            </b-col>
            <b-col
              v-if="model.status == 'open'"
              cols="12"
              class="mt-1"
            >
              <hr>
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-button
                  style="margin-right: 5px;"
                  variant="primary"
                  type="button"
                  @click.prevent="editData(model)"
                >
                  Edit
                </b-button>
                <b-button
                  style="margin-right: 5px;"
                  variant="success"
                  type="button"
                  @click.prevent="generateData()"
                >
                  Generate Perawatan
                </b-button>
                <b-button
                  style="margin-right: 5px;"
                  variant="danger"
                  type="button"
                  @click.prevent="confirmDelete(model)"
                >
                  Hapus
                </b-button>
                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
              
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon
            icon="ListIcon"
          /> Proses</h4>
          <view-time-line 
            :fields="data_time_line"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="TruckIcon" />
                Daftar Foto Issue Kendaraan
              </template>
                <b-button
                  v-show="model.status == 'open'"
                  class="mb-1"
                  variant="success"
                  type="button"
                  @click.prevent="upload()"
                >
                  Tambah Foto
                </b-button>
                <b-form-file v-show="false" id="upload" v-model="file" @input="selectFile"></b-form-file>
              <b-table
                  striped
                  responsive
                  :items="itemPhotoIssue"
                  :fields="fieldsPhotoIssue"
              >
              <template #cell(_)="data">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 btn-icon"
                  @click="deleteForm(data)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
              <template #cell(photo)="data">
                <img :src="data.item.photo_url" :alt="data.item.photo_url" width="500px">
              </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import {BRow, BCol, BButton,BSpinner,BCard,BTabs, BTab, BTable, BFormFile} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import moment from "moment"


export default {
  components: {

    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    FormLabel,
    ViewTimeLine,
    BTabs, 
    BTab, 
    BTable,
    BFormFile,
  },
  data() {
    return {
      loading :false,
      model:{},
      baseroute:'issue-kendaraan',
      posturl:'/issue',
      tombol_hide:true,
      ajukan:'',
      renderComp:true,
      title_modal:null,
      data_ajukan:[],
      data_item:{},
      data_time_line:[],
      itemPhotoIssue : null,
      fieldsPhotoIssue: [
        { key: 'photo', label: 'File Foto'},
        '_'
      ],
      file:null,
    }
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.getData()
      }
    }
  },
  beforeMount(){
    this.$http.get('/staff').then(ref=>{
        this.data_ajukan = ref.data.data
    })
  },
  mounted(){
    this.getData()
  },
  methods: {
     getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          this.model = res.data
          this.data_time_line = res.data.tracks
        })

        this.$http.get(this.posturl+'/'+this.$route.params.id+"/photo").then(res=>{
          this.itemPhotoIssue = res.data.data
        })
     },
    editData(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.id} })
    },
    generateData(){
      this.$router.push({ name: 'rencana-perawatan-create' })
    },
    tanggal(date){
        return moment(date).local('id').format('DD-MM-YYYY')
      },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.contract_subject != null ? 'Anda Ingin Menghapus Data '+data.contract_subject +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.posturl+'/'+data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })

            })
            .catch(err=>{
              this.errorSubmit(err)
            })
          }
        })
    },
   
    cancel(){
        this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    async printKesanggupan() {
      let print = await this.$http.get('issue/'+this.$route.params.id+'/kesanggupan/print')
      if(print){
        this.getData()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Surat Kesanggupan telah diprint',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        window.open(print.data.filename)
      }
    },
    upload(){
      document.getElementById('upload').click()
    },
    async selectFile(data){
      if(data){
        let formData = new FormData()
        formData.append('file', data)
        let filename = ""
        let url = 'upload/image'

        let resFile = await this.$http.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).catch(err=>{this.errorSubmit(err)})
        if(resFile){
          if(resFile.data.path.split("/"))
            filename = resFile.data.path.split("/").pop()
          
          let params = {
            photo: resFile.data.path
          }

          let url = 'issue/'+this.$route.params.id

          this.$http.post(url+'/photo', params)
          .then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false;})
        }
      }
    },
    deleteForm(data){
      this.$bvModal
        .msgBoxConfirm('Anda Ingin Menghapus Data '+data.item.photo +' ini ? ' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            let url = 'issue/'+this.$route.params.id

            this.$http.delete(url+'/photo/'+data.item.id)
            .then(()=>{
              this.getData()
              this.$bvToast.toast('Berhasil hapus photo', {
                title: 'Success',
                solid: true,
                variant:'success'
              })
            })
            .catch(err=>{
              const msg = err.response.data.message
              this.$bvToast.toast((msg)?msg:'Hapus photo error', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            })
          }
        });
    },
    successSubmit(){
      this.getData()
      this.$bvToast.toast('Berhasil upload', {
        title: 'Success',
        solid: true,
        variant:'success'
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  },
}
</script>
